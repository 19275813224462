import './scripts/common';
import {onFind} from "@elements/init-modules-in-scope";
import {translate} from '@elements/translations';
import {findIn} from '@elements/dom-utils';

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};

// Todo: Add page specific JS here e.g:
import * as lightbox from '@elements/lightbox';
lightbox.init();

import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/collapse';

import * as playVideo from './scripts/play-video';
playVideo.init();

import * as infoModal from './scripts/info-modal';
infoModal.init();

import * as scrollSlider from '@elements/scroll-slider';
scrollSlider.init({
    arrowIconClass:"icon icon-chevron-right",
    dots: true,
    arrowRenderFunction: arrowRenderFunction
}, {
    base: '.js-scroll-slider',
    area: '.js-scroll-slider__area',
    item: '.js-scroll-slider__item',
    dotsContainer: '.js-scroll-slider__dots-container'
});

function arrowRenderFunction(container, direction, disabled, options) {
    console.log("container",container);
    let markupElement = document.createElement('div'),
        arrowMarkup = `<button type="button" class="${options.arrowClass} ${direction === 0 ? options.arrowPrevClass : options.arrowNextClass}" ${disabled ? 'disabled' : ''}><span class="scroll-slider__arrow-icon icon ${direction === 0 ? 'icon-chevron-left' :  'icon-chevron-right' }" aria-label="${translate('scroll-slider-'+ direction + '')}" title="${translate('scroll-slider-'+ direction + '')}"></span></button>`;
    markupElement.innerHTML = arrowMarkup;
    let newArrow = markupElement.childNodes[0];
    let arrowContainer = findIn('.js-scroll-slider__arrow-wrapper', container);
    arrowContainer.appendChild(newArrow);
    return newArrow;
}
